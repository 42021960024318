.section-themebg{
    background: rgb(57,181,74);
    background: linear-gradient(90deg, rgba(57,181,74,1) 0%, rgba(0,182,241,1) 100%);
} 
.innerinstantquote{padding: 20px 0px;}
.free-sample{font-size: 12px;color:#000;text-decoration: underline;}
.free-sample:hover{color:#fff;}
.innerinstantquote .get-quote{padding: 8px 25px;}
.team_title{font-size:35px;font-weight:bold;line-height: 50px;}
.subdescription{font-size: 18px;}

.team-10 {
    text-align: center;
    margin-bottom: 30px;
    border-radius: 5px;
}
.team-10 span.bordertop{
    background: rgb(57,181,74);
    background: linear-gradient(90deg, rgba(57,181,74,1) 0%, rgba(0,182,241,1) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    height: 10px;
}

.team-10 .team-img {
    border-radius: 5px;
    overflow: hidden;
}

.team-10 .team-img img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.team-10 .team-content {
    position: relative;
    width: 90%;
    padding: 20px 15px;
    margin: -60px auto 0 auto;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 5px;
}
 
.team-10 .team-content h3 {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 10px;
}

.team-10 .team-content h5 {
    font-size: 16px;
    font-weight: 300;
}

 