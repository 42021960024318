.pricingtab-selection{padding: 0px;margin: 0;list-style: none;display: flex;justify-content: center;}
.pricingtab-selection li{    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 1rem;
    background: white;border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);  margin-right: 10px}
.business-tab-content{padding: 40px 70px;}
.pricingtab-selection li.active{ background-color:#00B373;color:#fff;}
.pricingtab-selection li img{margin-right: 8px;}
.pricingtab-selection li.active img{filter: brightness(0) invert(1);}
/* HIDE RADIO */
.format-info [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  .format-info [type=radio] + img {
    cursor: pointer; height: 70px;    border: 2px solid #eee;
  }
  
  /* CHECKED STYLES */
  .format-info [type=radio]:checked + img {
      border: 2px solid #00B373;
  }
  .format-selction-box {
    background: #fff;
    border: 1px solid #eee;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;box-shadow: 0px 0px 10px #eee;
}

.format-selction-box .format-info {
    margin-right: 10px; text-align: center; width: 100%;
}
.format-selction-box .format-info h5{font-size: 16px;font-weight: 400;margin-top: 10px;}
.format-selction-box .format-info input[type="range"] { width: 100%;}
.formatmain-box{margin-bottom: 20px;}
.price_box{color: #A5B7C6;text-align: right;}
.formatmain-box h4{margin-bottom:30px;}
.format-selction-box  .custom-control-label{padding-left: 10px;}



.delivery-selection input[type="radio"]{
	-webkit-appearance:none;
}
.delivery-selection  label{
	height: 120px;
	width: 170px;
	border:2px solid #00B373;
	position: relative;
	margin: auto;
	border-radius: 10px;
	color: #00B373;
	transition: 0.5s; margin-right: 10px;
}
.delivery-selection  i{
	font-size: 50px;
	position: absolute;
	top:50%;
	left: 50%;
	transform: translate(-50%,-80%);
}
.delivery-selection label>span{
	font-size: 16px;
	position: absolute;
	bottom:10%;
    right: 0px;
    left: 0; text-align: center;
	/* transform: translate(-50%,80%);
	font-family: "Poppins",sans-serif; */
	font-weight: 500;
}
.delivery-selection input[type="radio"]:checked + label{
	background-color: #00B373;
	color: white;
	box-shadow: 0 15px 45px rgba(24,249,141,0.2);
}

.drop-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 240px;
    border: 2px dotted #2697FF;
    text-align: center;
    background-color: #F5F8FA;
}
.dropbox-info {
    color: #2697FF;
    font-size: 14px;
}
.formatmain-box h2{font-size:35px;margin-bottom: 40px;line-height: 50px;}
.custom-pricing-table tr td:last-child{text-align: right;}
.custom-pricing-table tr td{border-top: 0px; padding: 5px;}
.calculation_box .small {font-size: 16px;margin-bottom: 6px;}

ul.business_list {
    padding: 0px;
    margin: 0;
    list-style: none;
}
ul.business_list li{
   font-size: 21px;line-height: 40px;
}
ul.business_list li i{margin-right: 10px;color:#37b24b;}

.title_box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;margin-bottom: 20px;
}
.title_box img{
    margin-right: 10px;height:35px;
}
.title_box h3{margin-bottom: 0;}



.checkbox-group {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none;
    user-select: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .checkbox-group > * {
    margin: 0.5rem 0.5rem;
  }
  
  .checkbox-group-legend {
    font-size: 1.5rem;
    font-weight: 700;
    color: #9c9c9c;
    text-align: center;
    line-height: 1.125;
    margin-bottom: 1.25rem;
  }
  
  .checkbox-input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
            clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .checkbox-input:checked + .checkbox-tile {
    border-color: #00B373;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #000;
  }
  .checkbox-input:checked + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
    background-color: #00B373;
    border-color: #00B373;
  }
  .checkbox-input:checked + .checkbox-tile .checkbox-icon, .checkbox-input:checked + .checkbox-tile .checkbox-label {
    color: #000;
    font-weight: 500;
  }
  .checkbox-input:focus + .checkbox-tile {
    border-color: #00B373;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
  }
  .checkbox-input:focus + .checkbox-tile:before {
    transform: scale(1);
    opacity: 1;
  }
  span.checkbox-label.service_title {
    color: #000;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;margin-top: 10px;
}
.service_pricing{  color: #555;margin-bottom: 10px; }
.service_content{font-size: 14px;line-height: 20px;}

.custom-service .checkbox-tile{display: block; padding: 3px 5px;min-height:auto;}
.custom-service label.checkbox-wrapper {
  width: 80%;    margin-bottom: 0px;
}
  .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px;
    width: 100%;
    min-height: 7rem;
    border-radius: 0.5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
  }
  .checkbox-tile:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .checkbox-tile:hover {
    border-color: #00B373;
  }
  .checkbox-tile:hover:before {
    transform: scale(1);
    opacity: 1;
  }
  
  .checkbox-icon {
    transition: 0.375s ease;
    color: #494949;
  }
  .checkbox-icon svg {
    width: 3rem;
    height: 3rem;
  }
  
  .checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
  }


  .checkbox-icon i{font-size: 35px;}


  .missionbox{padding: 20px 30px;background: #fff;border:1px solid #ddd;
    font-size: 18px;
}
  blockquote{
    font-size: 20px;
    width:90%;
    margin:50px auto;
    font-family:Open Sans;
    font-style:italic;
    color: #555555;
    padding:1.2em 30px 1.2em 75px;
    border-left:8px solid #78C0A8 ;
    line-height:1.6;
    position: relative;
    background:#f8f8f8;
  }
  
  blockquote::before{
    font-family:Arial;
    content: "\201C";
    color:#78C0A8;
    font-size:4em;
    position: absolute;
    left: 10px;
    top:-10px;
  }
  
  blockquote::after{
    content: '';
  }
  
  blockquote span{
    display:block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top:10px;
  }



  .payment-methods {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .payment-methods:after {
    content: "";
    clear: both;
  }
  
  .payment-method {
    
    box-sizing: border-box;
    float: left;
   
    height: 100px;
    position: relative;
    width: 170px

  }
  
  .payment-method label {
     
   
    cursor: pointer;
    display: block;
    font-size: 15px;
    left: 1px;
    position: absolute;
    right: 1px;
    padding: 10px;
    text-align: center;
    top: 1px;
    border-radius: 0.5rem;
    border: 1px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    
  }
  .payment-method label span{text-align: center;font-size: 40px;}
  .payment-method + .payment-method {
    margin-left: 25px;
  }
  
  /* .pagseguro label {
    background-image: url(https://dl.dropbox.com/s/yvzrr9o54s2llkr/uol.png);
  }
  
  .paypal label {
    background-image: url(https://dl.dropbox.com/s/i4z39zy2mtb7xq1/paypal.png);
  }
  
  .bankslip label {
    background-image: url(https://dl.dropbox.com/s/myj41602bom0g8p/bankslip.png);
  } */
  
  .payment-methods input:focus + label {
    outline: 1px dotted #21b4d0;
  }
  
  .payment-methods input:checked + label {
    outline: 2px solid #21b4d0;
  }
  
  .payment-methods input:checked + label:after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+Y2hlY2tlZDwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hlY2tlZCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTEwLjAwNDkyODUsMjAgQzE1LjQ5NTMxNzksMjAgMjAsMTUuNDkzMDk2NiAyMCwxMCBDMjAsNC40OTcwNDE0MiAxNS40OTUzMTc5LDAgOS45OTUwNzE0NiwwIEM0LjUwNDY4MjExLDAgMCw0LjQ5NzA0MTQyIDAsMTAgQzAsMTUuNDkzMDk2NiA0LjUwNDY4MjExLDIwIDEwLjAwNDkyODUsMjAgWiIgZmlsbD0iIzIxQjREMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNOS4wNDQ0MDE1NCwxNiBDOC41OTA3MzM1OSwxNiA4LjIzMzU5MDczLDE1Ljc3NDM1OSA3Ljk1MzY2Nzk1LDE1LjQyNTY0MSBMNS4zMzc4Mzc4NCwxMi4xNjQxMDI2IEM1LjA5NjUyNTEsMTEuODc2OTIzMSA1LDExLjYxMDI1NjQgNSwxMS4yOTIzMDc3IEM1LDEwLjYyNTY0MSA1LjUzMDg4ODAzLDEwLjA5MjMwNzcgNi4xNDg2NDg2NSwxMC4wOTIzMDc3IEM2LjUwNTc5MTUxLDEwLjA5MjMwNzcgNi43ODU3MTQyOSwxMC4yNTY0MTAzIDcuMDM2Njc5NTQsMTAuNTUzODQ2MiBMOS4wMjUwOTY1MywxMy4wNTY0MTAzIEwxMi44MTg1MzI4LDYuNjg3MTc5NDkgQzEzLjA5ODQ1NTYsNi4yMzU4OTc0NCAxMy40MTY5ODg0LDYgMTMuODMyMDQ2Myw2IEMxNC40NDAxNTQ0LDYgMTUsNi40ODIwNTEyOCAxNSw3LjE0ODcxNzk1IEMxNSw3LjQwNTEyODIxIDE0LjkwMzQ3NDksNy42OTIzMDc2OSAxNC43MzkzODIyLDcuOTU4OTc0MzYgTDEwLjEyNTQ4MjYsMTUuMzY0MTAyNiBDOS44NjQ4NjQ4NiwxNS43NTM4NDYyIDkuNDY5MTExOTcsMTYgOS4wNDQ0MDE1NCwxNiBaIiBpZD0iUGF0aCIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    bottom: -10px;
    content: "";
    display: inline-block;
    height: 20px;
    position: absolute;
    right: -10px;
    width: 20px;
  }
  
  @-moz-document url-prefix() {
    .payment-methods input:checked + label:after {
      bottom: 0;
      right: 0;
      background-color: #21b4d0;
    }
  }


  .service_content h5 {
    font-weight: 600 !important;
}
  .wrapper-radio{
    display: inline-flex;
     height: 100px;
    width: 90%;
    
     
    border-radius: 5px;
    padding: 12px 0px;
     
  }
  .wrapper-radio .option{
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
     
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
  }
  .wrapper-radio .option .dot{
    height: 20px;
    width: 20px; margin-right: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
  }
  .wrapper-radio .option .dot::before{
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #25b0e7;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
  }
  .wrapper-radio input[type="radio"]{
    display: none;
  }
  #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2{
    border-color: #25b0e7;
    background: #25b0e7;
  }
  #option-1:checked:checked ~ .option-1 .dot,
  #option-2:checked:checked ~ .option-2 .dot{
    background: #fff;
  }
  #option-1:checked:checked ~ .option-1 .dot::before,
  #option-2:checked:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
  }
  .wrapper-radio .option span{
    font-size: 17px;
    color: #808080;
  }
  #option-1:checked:checked ~ .option-1 span,
  #option-2:checked:checked ~ .option-2 span{
    color: #fff;
  }