.combineform{background-color: #f8f8f8;padding: 60px 0px;}
.signup {
    margin-bottom: 150px
}

.signup-content, .signin-content {
    padding: 40px 40px;    background: #fff;
    box-shadow: 0px 0px 12px #ddd;
    border-radius: 10px;
}
 .signup-content, .signin-content {
    display: inline-block;
    /* display: -webkit-flex; */
    width: 100%;
    

}

.signup-image {
    margin: 0 55px
}

.form-title {
    margin-bottom: 33px
}

.signup-image {
    margin-top: 45px
}

.combineform figure {
    margin-bottom: 50px;
    text-align: center
}

.form-submit {
    display: inline-block;
    background: #24afe6;
    color: #fff;
    border-bottom: none;
    width: auto;
    padding: 15px 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
     
    cursor: pointer
}

.form-submit:hover {
    background: #4292dc
}

#signin {
    margin-top: 16px
}

.signup-image-link {
    font-size: 14px;
    display: block;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
}

.forget-password-link{
    font-size: 14px;
    display: block;
    text-align: center;
    width: fit-content;
    margin: 10px auto; text-decoration: underline;color:#000;
}

.term-service {
    font-size: 13px;
    color: #222
}
 

.register-form {
    width: 100%
}

.combineform .form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden
}

.combineform  .form-group:last-child {
    margin-bottom: 0
}

.combineform  input, .combineform select {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    box-sizing: border-box
}
.combineform input[type="radio"], .combineform input[type="checkbox"] {
    display: inline-block !important; width: auto;
}

.combineform  input::-webkit-input-placeholder {
    color: #999
}

.combineform  input::-moz-placeholder {
    color: #999
}

.combineform  input:-ms-input-placeholder {
    color: #999
}

.combineform  input:-moz-placeholder {
    color: #999
}

.combineform  input:focus {
    border-bottom: 1px solid #222
}

.combineform input:focus::-webkit-input-placeholder {
    color: #222
}

.combineform input:focus::-moz-placeholder {
    color: #222
}

.combineform input:focus:-ms-input-placeholder {
    color: #222
}

.combineform input:focus:-moz-placeholder {
    color: #222
}

/*input[type=checkbox]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    display: none
}

input[type=checkbox]:not(old)+label {
    display: inline-block;
    line-height: 1.5em;
    margin-top: 6px
}

input[type=checkbox]:not(old)+label>span {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-bottom: 3px;
    border: 1px solid #999;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    background: #fff;
    background-image: -moz-linear-gradient(white,white);
    background-image: -ms-linear-gradient(white,white);
    background-image: -o-linear-gradient(white,white);
    background-image: -webkit-linear-gradient(white,white);
    background-image: linear-gradient(white,white);
    vertical-align: bottom
}

input[type=checkbox]:not(old):checked+label>span {
    background-image: -moz-linear-gradient(white,white);
    background-image: -ms-linear-gradient(white,white);
    background-image: -o-linear-gradient(white,white);
    background-image: -webkit-linear-gradient(white,white);
    background-image: linear-gradient(white,white)
}

input[type=checkbox]:not(old):checked+label>span:before {
    content: '\f26b';
    display: block;
    color: #222;
    font-size: 11px;
    line-height: 1.2;
    text-align: center;
    font-family: material-design-iconic-font;
    font-weight: 700
}*/

.agree-term {
    display: inline-block;
    width: auto
}

.combineform  label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222
}

.combineform .label-has-error {
    top: 22%
}

.combineform label.error {
    position: relative;
    /* background: url(../images/unchecked.gif) no-repeat; */
    background-position-y: 3px;
    padding-left: 20px;
    display: block;
    margin-top: 20px
}

.combineform label.valid {
    display: block;
    position: absolute;
    right: 0;
    left: auto;
    margin-top: -6px;
    width: 20px;
    height: 20px;
    background: 0 0
}

 

.label-agree-term {
    position: relative !important;
    top: unset !important; 
    transform:unset !important;left:unset !important;
      margin-left: 9px;font-size: 13px;line-height: 17px;    vertical-align: middle;
}

.combineform  input:focus-visible, .combineform input:focus{outline: 0;}

.material-icons-name {
    font-size: 18px
}

.signin-content {
    padding-top: 67px;
    padding-bottom: 87px
}

/*
.social-login {
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
    margin-top: 80px
}

.social-label {
    display: inline-block;
    margin-right: 15px
}

.socials li {
    padding: 5px
}

.socials li:last-child {
    margin-right: 0
}

.socials li a {
    text-decoration: none
}

.socials li a i {
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    transform: translateZ(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}

.socials li:hover a i {
    -webkit-transform: scale(1.3) translateZ(0);
    transform: scale(1.3) translateZ(0)
}

.zmdi-facebook {
    background: #3b5998
}

.zmdi-twitter {
    background: #1da0f2
}

.zmdi-google {
    background: #e72734
}*/

 
.signin-image {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 10px
}

 

 
@media screen and (max-width: 768px) {
    .signup-content,.signin-content {
        flex-direction:column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column;
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center
    }

    .signup-form {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding: 0 30px
    }

    .signin-image {
        margin-left: 0;
        margin-right: 0;
        margin-top: 50px;
        order: 2;
        -moz-order: 2;
        -webkit-order: 2;
        -o-order: 2;
        -ms-order: 2
    }

    .signup-form,.signup-image,.signin-form,.signin-image {
        width: auto
    }

    .social-login {
        justify-content: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -o-justify-content: center;
        -ms-justify-content: center
    }

    .form-button {
        text-align: center
    }

    .signin-form {
        order: 1;
        -moz-order: 1;
        -webkit-order: 1;
        -o-order: 1;
        -ms-order: 1;
        margin-right: 0;
        margin-left: 0;
        padding: 0 30px
    }

    .form-title {
        text-align: center
    }
}

@media screen and (max-width: 400px) {
    .social-login {
        flex-direction:column;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        -o-flex-direction: column;
        -ms-flex-direction: column
    }

    .social-label {
        margin-right: 0;
        margin-bottom: 10px
    }
}



button#dropdownMenuButton{
    background: none;
    border: none;
    padding: 0;
}