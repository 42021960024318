.comparisonimage {
    background: #35b556;
    padding: 10px;
}
.text-red{ color: red !important;}
.text-green{ color:green !important;}

.service_main_title{font-size: 80px;font-weight:600;line-height: 80px;}
.feature_list{padding: 0;margin: 0;list-style: none;}
.feature_list li{margin-bottom: 10px;}
.feature_list li i{color:#35b556;margin-right: 12px;}
.service_sub_title{  font-size: 55px;
    line-height: 60px;font-weight: 600;margin-bottom: 0px;}


    .teacher-list{padding:0;margin:0;list-style:none;}
.teacher-list li{display:inline-block;width: 24%;overflow: hidden; margin-right: 3px;line-height: 16px;margin-bottom:10px;text-align:left;}
.teacher-list li h4{margin-bottom:0px;font-size: 15px;color:#1A1A2B;font-weight:700;}
.teacher-list li span{display:block;color:#797995;font-size: 10px;}
.teacher-list li img{width:100%;}
.teacher-list li span i {
    margin-right: 2px;
}
.work_box h3 {
    font-size: 20px;
}
.work_box .img-box{height: 60px;}
.work_box {
    padding: 20px 10px;
    border: 1px solid #eee;
}
.work_box:hover{box-shadow: 0px 0px 10px #ddd;}
.work_box p{font-size: 14px;}