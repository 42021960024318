.custom-card-form{background-color: #fff;padding: 30px; box-shadow:0px 0px 10px #d3d3d3;}

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?6pcvtv');
    src:  url('../fonts/icomoon.eot?6pcvtv#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?6pcvtv') format('truetype'),
      url('../fonts/icomoon.woff?6pcvtv') format('woff'),
      url('../fonts/icomoon.svg?6pcvtv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  /* [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */
  
  .icon-circle-o:before {
    content: "\e900";
    color: #9FA0B3;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .greenTick{
    display: block;
    font-size: 29px;
    color: #fff;
    padding: 7px;
    border: 1px solid green;
    width: 46px;
    text-align: center;
    margin: 0 auto;
    background: green;
    margin-bottom: 7px;
  }


.paypalbutton .paypal-buttons , .paypal-button-number-1 , .paypal-powered-by{
    display: none !important;
}
.paypal-button-row.paypal-button-number-1.paypal-button-layout-vertical.paypal-button-shape-rect.paypal-button-number-multiple.paypal-button-env-sandbox.paypal-button-color-black.paypal-button-text-color-white.paypal-logo-color-white {
  display: none;
}
.paypal-powered-by {
  display: none;
}
.paypalbutton .paypal-buttons:nth-child(1) {
  display: block !important;
}

.paybutton{
  padding: 11px;
    font-weight: bold;
    margin-bottom: 15px !important;
    width: auto;
    margin: auto;
}
.paypal-button-container.paypal-button-layout-vertical.paypal-button-shape-rect.paypal-button-number-multiple{
  margin: auto !important;
}
label.p-FieldLabel.Label.Label--empty , .label.p-FieldLabel.Label {
  padding: 11px !important;
}
#payment-form #submit{
  color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    padding: 9px;
    width: 116px;
    margin: 11px;
}
.strip_form{
  padding: 11px;
}
.paypal_form{
  margin: auto;
    padding: 16px;
}

.payment-summary .payment-title {
  line-height: 13px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 24px; text-transform: capitalize;margin-bottom: 20px;
   
}
.order-summary p {
  line-height: 12px;
  margin-bottom: 15px !important;
}